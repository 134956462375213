import React from 'react'
import theme from "../../utils/theme";
const SubscriptionIcon = ({ height, width ,color}) => {
  // const color=theme.colors.color[900]
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 14" fill="none">
    <path d="M4.92807 8.0887L2.59246 7.07031L1 11.8567L2.59246 11.1439L3.44177 12.5696L4.92807 8.0887Z" fill={color} stroke={color}/>
    <path d="M7.9601 6.9831L5.55004 8.03116L7.81232 12.5101L8.45277 10.829L10.0812 11.1728L7.9601 6.9831Z" fill={color} stroke={color}/>
    <circle cx="5.32087" cy="4.32087" r="3.82087" fill="white" stroke={color}/>
  </svg>
  )
}

export default SubscriptionIcon