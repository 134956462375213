import { Box,Spinner  } from "@chakra-ui/react";
import Cardsdashboad from "Cardsdashboad";
import Des from "Navbar";
import Sidebar from "Sidebar";
import theme from  "utils/theme" 

import Header from "components/common/Header";

import React, { lazy, Suspense, useEffect, useState } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import PrivateRoute from "utils/PrivateRoute";
import urls from "utils/routes";


const App = () => {
  const location = useLocation();
 
  // const Cardsdashboad = lazy(() => import("Cardsdashboad"));
  // const Sidebar = lazy(() => import("Sidebar"));
  const BelllIcon = lazy(() => import("assets/icons/BellIcon"));
  const KillSwitchIcon = lazy(() => import("assets/icons/KillSwitchIcon"));
  const LogoutIcon = lazy(() => import("assets/icons/LogoutIcon"));
  const ProfileIcon = lazy(() => import("assets/icons/ProfileIcon"));
  const BrokerMapping = lazy(() => import("components/BrokerMapping/BrokerMapping"));
const  ClientrequestTabs =lazy(()=> import("components/Clientrequests/ClientrequestTabs"))
  // const Des = lazy(() => import("./Navbar"));
  const ClientStrategy = lazy(() =>
    import("components/clients/ClientStrategies")
  );
  const SubscriptionTabs = lazy(() =>
    import("components/subsciption/SubscriptionTabs")
  );
  const Notification = lazy(() => import("components/common/Notification"));
  const Tabbs = lazy(() => import("components/summary/Tabbs"));
  const FilterErrorReq = lazy(() =>
    import("components/Error-orders/FilterErrorReq")
  );
  const Login = lazy(() => import("components/auth/Login"));
  const Dashboard = lazy(() => import("components/dashboard"));
  const Clients = lazy(() => import("components/clients"));
  const Profile = lazy(() => import("components/profile"));
  const NotFound = lazy(() => import("components/common/NotFound"));
  const NewStrategy = lazy(() => import("components/strategy/new-strategy"));
  const MyStrategy = lazy(() => import("components/strategy/my-strategy"));
  const NewPortfolio = lazy(() => import("components/portfolio/new-portfolio"));
  const AddEditPortfolio = lazy(() =>
    import("components/modals/AddEditPortfolio")
  );
  const BasketEdit = lazy(() => import("components/modals/BasketEdit"));
  const MyPortfolio = lazy(() => import("components/portfolio/my-portfolio"));
  const OpenRequest = lazy(() => import("components/openReq/my-openReq"));
  const ClientPortfolio = lazy(() =>
    import("components/portfolio/client-portfolio")
  );
  const ViewMyPortfolio = lazy(() =>
    import("components/portfolio/view-my-portfolio")
  );
  const OrderPlacement = lazy(() => import("components/order-placement"));
  const OpenPosition = lazy(() => import("components/open-position"));
  const Summary = lazy(() => import("components/summary"));
  const MyOrderBook = lazy(() => import("components/orderbook/my-orderbook"));
  const VirtualOrderBook = lazy(() =>
    import("components/orderbook/virtual-orderbook")
  );
  const StrategyTable = lazy(() => import("components/summary/StrategyTable"));
  const PortfolioTable = lazy(() =>
    import("components/summary/PortfolioTable")
  );
  const Graph = lazy(() => import("components/summary/Graph/Graph"));
  const SubscriptionTable = lazy(() =>
    import("components/subsciption/SubscriptionTable")
  );
  const StrategySummary = lazy(() => import("components/strategy/summary"));
  const PortfolioSummary = lazy(() => import("components/portfolio/summary"));
  const FilterPortfolio = lazy(() =>
    import("components/summary/FilterPortfolio")
  );
  const FilterStrategy = lazy(() =>
    import("components/summary/FilterStrategy")
  );
  const PendingOrders = lazy(() => import("components/Error-orders/index"));
  const allRoutes = useRoutes([
    {
      path: urls.login,
      element: <Login />,
    },
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        {
          path: urls.dashboard,
          element: <Dashboard />,
        },
        {
          path: urls.clients,
          element: <Clients />,
        },

        {
          path: urls.broker,
          element: <BrokerMapping />,
        },
        {
          path: urls.profile,
          element: <Profile />,
        },
        {
          path: urls.createStrategy,
          element: <NewStrategy />,
        },
        {
          path: urls.editStrategy,
          element: <NewStrategy isEdit={true} />,
        },
        {
          path: urls.copyStrategy,
          element: <NewStrategy isCopy={true} />,
        },
        {
          path: urls.myStrategy,
          element: <MyStrategy />,
        },
        {
          path: urls.createPortfolio,
          element: <NewPortfolio />,
        },
        { path: urls.P_and_L, element: <Tabbs /> },
        {
          path: urls.editPortfolio,
          element: <NewPortfolio isEdit={true} />,
        },
        {
          path: urls.copyPortfolio,
          element: <NewPortfolio isCopy={true} />,
        },
        {
          path: urls.openRequest,
          element: <OpenRequest />,
        },
        {
          path: urls.addEditPortfolio,
          element: <AddEditPortfolio />,
        },
        {
          path: urls.BasketEdit,
          element: <BasketEdit />,
        },
        {
          path: urls.myPortfolio,
          element: <MyPortfolio />,
        },
        {
          path: urls.clientPortfolio,
          element: <ClientPortfolio />,
        },
        {
          path: urls.viewMyPortfolio,
          element: <ViewMyPortfolio />,
        },
        {
          path: urls.orderPlacement,
          element: <OrderPlacement />,
        },
        {
          path: urls.openPosition,
          element: <OpenPosition />,
        },
        {
          path: urls.summary,
          element: <Summary />,
        },
        {
          path: urls.StrategyTable,
          element: <StrategyTable />,
        },
        {
          path: urls.PortfolioTable,
          element: <PortfolioTable />,
        },
        {
          path: urls.Graph,
          element: <Graph />,
        },

        {
          path: urls.FilterPortfolio,
          element: <FilterPortfolio />,
        },
        {
          path: urls.FilterStrategy,
          element: <FilterStrategy />,
        },
        {
          path: urls.myOrderBook,
          element: <MyOrderBook />,
        },
        {
          path: urls.VirtualOrderBook,
          element: <VirtualOrderBook />,
        },
        {
          path: urls.SubscriptionTable,
          element: <SubscriptionTable />,
        },
        {
          path: urls.portfolioSummary,
          element: <PortfolioSummary />,
        },
        {
          path: urls.strategySummary,
          element: <StrategySummary />,
        },
        {
          path: urls.ErrorOrders,
          element: <FilterErrorReq />,
        },
        {
          path: urls.PendingOrders,
          element: <PendingOrders />,
        },
        { path: urls.ClientStrategy, element: <ClientStrategy /> },
        {
          path: urls.notifications,
          element: <Notification />,
        },
        {
          path: urls.SubscriptionTabs,
          element: <SubscriptionTabs />,
        },
        {
          path:urls.ClientrequestTabs,
          element:<ClientrequestTabs />
        }
      ],
    },
    {
      path: urls.addEditPortfolio,
      element: <AddEditPortfolio />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  // localStorage.getItem("accessToken") &&
  // location.pathname == "/"
  const pathname = window.location.pathname;


  return (
  
    <Box
      w="100%"
      bg="brand.100"
      h="100vh"
      css={{
        "&::-webkit-scrollbar": {
          width: "7px",
         
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.colors.brand[900], // Customize thumb color
          borderRadius: "4px", // Optional: Customize thumb border radius
          
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "gray.200", // Customize track color
        
        },
      }}
      position={
        pathname !== "/summary-graph" && pathname !== "/profile/edit"
          ? "fixed"
          : ""
      }
    >
      {localStorage.getItem("accessToken") && <Des />}

      <Box display="flex" flexDirection={"row"}>
        {localStorage.getItem("accessToken") && <Sidebar  />}

        <Box w={urls.login === "/" ? "100%" : "92%"}  bg="brand.100">
          {localStorage.getItem("accessToken") && <Cardsdashboad />}
        <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
          >
            {/* Use the Chakra UI Spinner component */}
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.900" size="xl" />
          </Box>
        }
      >

            <Box
              w={urls.login === "/" ? "100%" : "98%"}
              pl={localStorage.getItem("accessToken") && "20px"}
            >
              {allRoutes}
            </Box>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export default App;
