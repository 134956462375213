// import instance from "utils/interceptor";
import instance, { request } from "utils/interceptor";
export const createClient = async (data, method = "get") => {
  let url = ``;
  // let requestData = method !== "get" ?  data : {}
  if (method == "put") {
    url = `/api/v2/clients/${data.id}`;
  } else if (method == "post") {
    url = `/api/v2/clients`;
  } else {
    url = `/api/v2/clients/?size=100`;
  }

  try {
    const res = await instance[method](url, data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getClient = async (data, method = "get") => {
  try {
    const res = await instance[method](`/api/v2/clients/?id=${data.id}&size=1`);
    return res;
  } catch (error) {
    throw error;
  }
};

// export const updateClient = async (data, method = "put") => {
//   try {
//     const res = await instance[method]("/api/v2/clients", data);
//     return res;
//   } catch (error) {
//     throw error;
//   }
// };
// export const deleteClient = async (data, method = "delete" ) => {
//   try {
//     const res = await instance[method]("/api/v2/clients", data);
//     return res;
//   } catch (error) {
//     throw error;
//   }
// };

export const deleteClient = async (data) => {
  try {
    // const res = await instance[method]("/api/v2/clients", data);
    const res = await instance.delete(`/api/v2/clients/${data.id}`, false);
    return res;
  } catch (error) {
    throw error;
  }
};

export const KillSwitch = async (url, headers, method = "post") => {
  try {
    const res = await request(url, method, headers, false);
    return res;
  } catch (error) {
    throw error;
  }
};

export const clientIsActive = async (data) => {
  try {
    const res = await instance.post(
      `/api/v2/clients/update_is_active-client`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const clientIsActive1 = async (data) => {
  try {
    const res = await instance.post(
      `/api/v2/clients/update-paused-client/`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const clientActivepauseRequest= async (data) => {
  try {
    const res = await instance.post(
      `/api/v2/clients/paused-client`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};


export const clientIsDiscard = async (data) => {
  try {
    const res = await instance.post(`/api/v2/clients/discard-client`, data);
    return res;
  } catch (error) {
    throw error;
  }
};
// api/v2/clients/?is_active=0&is_discard=1
// api/v2/clients/?is_active=0
// api/v2/clients/?is_discard=1
// api/v2/clients/?is_active=1

export const activeee = async (data) => {
  try {
    const res = await instance.get(`api/v2/clients/?is_active=1`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getClientsList = async (
  page = 0,
  size,
  name = "",
  endpoint
) => {
  var url;
  if (endpoint === "active") {
    url = `/api/v2/clients/?page=${page}&size=${size}&is_active=1&is_discard=0${
      name ? `&name=${name}` : ""
    }`;
  } else if (endpoint === "pause") {
    url = `/api/v2/clients/?page=${page}&size=${size}&is_active=0&is_discard=0${
      name ? `&name=${name}` : ""
    }`;
  } else if (endpoint === "discard") {
    url = `/api/v2/clients/?page=${page}&size=${size}&is_discard=1${
      name ? `&name=${name}` : ""
    }`;
  } else {
    url = `/api/v2/clients/?page=${page}&size=${size}${
      name ? `&name=${name}` : ""
    }`;
  }
  try {
    const res = await instance.get(
      // `/api/v2/clients/?page=${page}&size=${size}${name ? `&name=${name}` : ""}`
      url
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export const getClientsListPL = async () => {
  try {
    let res = await instance.get(`/api/v2/clients/clientpl-list?size=1000`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAccestokenPl = async () => {
  try {
    let res = await instance.get(`/api/v2/clients/clientpl-update`);
    return res;
  } catch (error) {
    throw error;
  }
};



export const getIndivisualClientsListPL = async (email) => {
  try {
    let res = await instance.get(`/api/v2/clients/clientpl-list?email=${email}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const searchIndivisualClientsList = async (name,page) => {
  try {
    let res = await instance.get(`/api/v2/clients/clientpl-list?name=${name}`);
    return res;
  } catch (error) {
    throw error;
  }
};
export const checkAccessToken = async () => {
  try {
    let res = await instance.post("/api/v2/clients/update-started-fund");
    return res;
  } catch (error) {
    throw error;
  }
};
export const SubscriptionCheck = async () => {
  try {
    let res = await instance.post("/api/v2/clients/check-subscription");
    return res;
  } catch (error) {
    throw error;
  }
};

export const randomUrlCall = async () => {
  // const { email, secret_key } = data;
  try {
    const res = await instance.post(
      // `/api/v2/orders/avg/?email=${email}&secret_key=${secret_key}`
      `/api/v2/orders/avg`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const todaysAvg = async () => {
  // const { email, secret_key } = data;

  try {
    const res = await instance.post(
      // `/api/v2/orders/today-avg/?email=${email}&secret_key=${secret_key}`
      `/api/v2/orders/today-avg`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

// get client list
export const getUserList = async (page = 0, size = 50) => {
  try {
    const res = await instance.get(`/api/v2/users?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const userProfile = async (data = "", method = "get") => {
  try {
    const res = await instance[method](`/api/v2/users/profile`, data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadExcelSheet = async () => {
  try {
    const res = await instance.get(`api/v2/orders/download`);
    return res;
  } catch (error) {}
};

// get client strategy list
export const getClientStrategyList = async (email) => {
  try {
    const res = await instance.get(
      `/api/v2/client-strategies/count-cs?email=${email}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getClientRequestList = async (condition) => {
  let type = condition===1 ?"PENDING" :condition ===2 ? "ACCEPT" :"REJECT"
  try {
    const res = await instance.get(
      `/api/v2/clients/paused-request-list?action=${type}&request_type=CLIENT`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

