// theme.js
import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};
let primary_color =
  localStorage.getItem("primary_color") || process.env.REACT_APP_PRIMARY_COLOR;
let secondary_color =
  localStorage.getItem("secondary_color") ||
  process.env.REACT_APP_SECONDARY_COLOR;

const theme = extendTheme({
  colors: {
    brand: {
      // 900: "rgba(0, 0, 255, 0.60)",//
      // 100:"F3F7FE",
      // 200:"#E8EBFE",
      // 300:"linear-gradient(to right, #B0A3FF, #7262FF, #7263FF,#B0B3FF)",
      // 600:"rgba(0, 0, 255, 0.60)",
      // 500: "white",

      700: "white", // moti

      400: "#333333", // moti

      300: "linear-gradient(90deg, rgba(241,232,224,1) 0%, rgba(228,154,32,1) 30%, rgba(230,221,205,1) 91%)",
      900: "#FECE72", // moti
      100: "#FEF8E6",
      200: "rgba(252, 175, 23, 0.15)",
      600: "#333333",
      500: "#333333", //moti fo
    },
  },
  components: {
    Avatar: {
      sizes: {
        md: {
          container: {
            height: "36px",
            width: "36px",
            background: primary_color || process.env.REACT_APP_PRIMARY_COLOR,
            color: "#141414",
          },
          label: {
            fontSize: 16,
          },
        },
      },
      variants: {
        outline: {
          container: {
            background:
              secondary_color || process.env.REACT_APP_SECONDARY_COLOR,
            color: primary_color || process.env.REACT_APP_PRIMARY_COLOR,
            border: `1px solid  ${
              primary_color || process.env.REACT_APP_PRIMARY_COLOR
            }`,
          },
        },
      },
    },
    Button: {
      variants: {
        outline: {
          border: `1px solid  ${secondary_color} || ${process.env.REACT_APP_SECONDARY_COLOR}`,
          background: secondary_color || process.env.REACT_APP_SECONDARY_COLOR,
          color: primary_color || process.env.REACT_APP_PRIMARY_COLOR,
        },
      },
    },

    Tabs: {
      variants: {
        custom: {
          tablist: {
            background: "white",
            padding: 1,
            borderRadius: "6px",
            fontWeight: 700,
          },
          tab: {
            color: "black",
            fontWeight: "700",
            _selected: {
              background: "brand.900",
              borderRadius: "8px",
              color: "white",
              fontWeight: 700,
            },
          },
        },
      },
    },
  },
  styles: {
    global: {
      "::-webkit-scrollbar": {
        width: "8px", // Reduce width
        height: "8px", // Reduce height
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "brand.900",
        borderRadius: "4px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "gray.200",
      },
    },
  },
  ...config,
});

export default theme;
