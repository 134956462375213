import axios from "axios";
import { getDomainName } from "utils/utils";

const domain = getDomainName(window.location.href);
const url = new URL(window.location.href);

const host = url.host;

localStorage.setItem("domainName", domain);

const ApiObject = {
  // "arjunv2.dronaquant.com": "https://arjunv2api.dronaquant.com",
  "arjunv2.keev.tech": "https://arjunv2api.keev.tech",
  "mofs.keev.tech": "https://mofsapi.keev.tech",

  "localhost:3000": "https://dronauatapi.keev.tech",

  // "localhost:3001": "https://mofsapi.dronaquant.com/",
  "localhost:3002": "https://dronauatapi.keev.tech",

  "dronauat.keev.tech": "https://dronauatapi.keev.tech",
  // "localhost:3001": "https://api.dronaquant.com",
};

const apiurl = ApiObject[host];

const instance = axios.create({
  baseURL: apiurl,
  timeout: 10000,
});
instance.defaults.timeout = 250000;
instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["Accept"] = "application/json";

instance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("accessToken");
    // 08045574091
    if (authToken) {
      config.headers["Authorization"] = "Bearer " + authToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 500) {
    } else if (error.response.status === 403) {
    } else if (error.response.status === 404) {
    } else if (error.response.status === 400) {
    } else if (error.response.status === 409) {
    } else if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  // Add caching headers to the request
  config.headers["Cache-Control"] = "max-age=3600"; // Cache data for 1 hour
  return config;
});
// export const request = (url, method = "GET", data, isBaseChange = false) => {
//   return instance({
//     url: url,
//     //...{baseURL : isBaseChange ? `${SERVER_URL}/tradingview/`:API_URL},
//     method: method,
//     data: data,
//   });
// };
export const request = (
  url,
  method = "GET",
  data,
  isBaseChange = false,
  params = {}
) => {
  return instance({
    url: url,
    method: method,
    data: data,
    params: params, // Include query parameters
  });
};

export default instance;
