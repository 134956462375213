import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import colors from "styles/colors";

const FormInput = (props) => {
  const {
    name,
    label,
    control,
    defaultValue,
    newValue,
    error,
    variant,
    rules,
    leftIcon,
    rightIcon,
    size,
    onChangeNew,
    placeholder,
    isRequired,
    ...rest
  } = props;
  let primary_color=localStorage.getItem("primary_color")
  let secondary_color=localStorage.getItem("secondary_color")
  const methods = useFormContext();
  return (
    <FormControl
      isInvalid={error && error[name] ? error[name]?.message : error?.message}
      isRequired={isRequired}
    >
      {label && (
        <FormLabel fontSize={14} fontWeight="700" color="#454545">
          {label}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { onChange, onBlur, name: fieldName, value } }) => (
          <InputGroup>
            <Input
              {...rest}
              onChange={
                onChangeNew
                  ? onChangeNew
                  : (e) => {
                      methods.setValue(name, e.target.value);
                      methods.clearErrors(name);
                    }
              }

              value={newValue ? newValue : value}
              defaultValue={defaultValue}
              errorBorderColor={error && error[name]?.message && "red.500"}
              size={size || "md"}
              placeholder={placeholder}
              variant={variant || "outline"}
              borderColor={
                error && error[name]?.message ? "red.500" : "#DCDCDC"
              }
              backgroundColor={"white"}
              fontWeight="700"
              borderRadius={"4px"}
              _disabled={{
                background: "#DBF5F0",
             
                borderColor: "#DBF5F0",
              
                borderColor:"bramd.900",
                ":hover": {
                  borderColor: "#DBF5F0",
                
                },
              }}
              _placeholder={{
                color: "#454545",
              }}
            />
            {leftIcon && <InputLeftElement children={leftIcon} />}
            {rightIcon && <InputRightElement children={rightIcon} />}
          </InputGroup>
        )}
      />
      {error && (
        <FormErrorMessage>
          {error && error[name] ? error[name]?.message : error?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormInput;

