import {
  Box,
  Flex,
  Grid,
  HStack,
  Image,
  Portal,
  Text,
  Toast,
  Tooltip,
} from "@chakra-ui/react";

import BelllIcon from "assets/icons/BellIcon";
import KillSwitchIcon from "assets/icons/KillSwitchIcon";
import LogoutIcon from "assets/icons/LogoutIcon";

import ProfileIcon from "assets/icons/ProfileIcon";
import logo from "assets/images/newdrona.png";
import {
  Avatar,
  Button,
  Card,
  GridItem,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { notification, seenNotification } from "apis/subscription";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { KillSwitch } from "apis/client";
import { BiBell } from "react-icons/bi";
import urls from "utils/routes";
import theme from "utils/theme"
const Des = () => {
  const [subscription, setSubscriptions] = useState([]);
  const navigate = useNavigate();
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    notification()
      .then((res) => {
        setSubscriptions(res.data[0]?.items);
      })
      .catch((error) => {
        throw error;
      });
    return () => {
      seenNotification().then((res) => {});
    };
  }, []);
  let admin = localStorage.getItem("admin");

  // const location = useLocation();

  // if (location.pathname == "/") {
  //   return null;
  // }
  const logout = () => {
    localStorage.clear();

    navigate("/");
    // window.location.reload();
  };
  const handleKillSwitch = () => {
    let token = localStorage.getItem("accessToken");
    let headers = {
      "Content-type": "application/json",
      AUTHENTICATION: token,
    };

    KillSwitch("/api/v1/clients/kill-switch", headers);
  };
  return (
    <>
      <Box bg="#F3F7FE" w="100%">
        <Box display={"flex"} w="100%" height="2.6875rem">
          <Box
            // width="115px"
            width="7%"
            bg="white"
            height="2.6875rem"
            color="#00F"
            fontFamily="body"
            fontSize="1.5375rem"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="normal"
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            ml="1"
            onClick={() => navigate("/dashboard")}
            cursor={"pointer"}
          >
            <Image w="100%" h="100%" src={logo} />
          </Box>
          <Box
            float="right"
            w="93%"
            height="2.6875rem"
            flexShrink="0"
            bg="brand.300"
            // bg="linear-gradient(to right, #B0A3FF, #7262FF, #7263FF,#B0B3FF)"
            // bg="linear-gradient(90deg, rgba(241,232,224,1) 0%, rgba(228,154,32,1) 30%, rgba(230,221,205,1) 91%)"
            display="flex"
            alignItems="center"
            justifyContent={"end"}
          >
            <Tooltip
              label="Profile"
              hasArrow
              placement="bottom"
              bg=" brand.900"
              color="brand.400"
            >
              <span>
                <ProfileIcon
                  navigatee={"/profile/edit"}
                  height={"32px"}
                  width="32px"
                />
              </span>
            </Tooltip>

            <Tooltip
              label="Notification"
              hasArrow
              placement="bottom"
              bg=" brand.900"
              color="brand.400"
            >
              <span>
                <Popover placement="bottom" zIndex={21}>
                  <PopoverTrigger>
                    <IconButton
                      icon={<BelllIcon height={"32px"} width="32px" />}
                      aria-label="Open menu"
                      variant="unstyled"
                    />
                  </PopoverTrigger>

                  <PopoverContent w="300px" mr="5">
                    <PopoverArrow />

                    <PopoverBody>
                      <Box>
                        <HStack mb="6">
                          <Text
                            fontSize="15px"
                            fontWeight="700"
                            borderBottom={"4px solid rgba(0, 0, 255, 0.60)"}
                          >
                            Notifications
                          </Text>
                          <BiBell bg="black" fontSize={"22px"} />
                        </HStack>
                        <Grid
                          templateColumns="repeat(1, 1fr)"
                          gap="5"
                          h="300px"
                          overflowY="auto"
                        
                        >
                          {subscription?.length > 0 ? (
                            subscription.map((item, index) => {
                              return (
                                <GridItem cursor={"pointer"} >
                                  <Card
                                    bg={
                                      item.seen === "0"
                                        ? "gray.100"
                                        : "whiteAlpha.900"
                                    }
                                    boxShadow="rgba(0, 0, 0, 0.24) 0px 2px 8px"
                                    h="50px"
                                    display={"flex"}
                                    p="5"
                                    //   alignItems={"center"}
                                    justifyContent={"center"}
                                    onClick={() => {
                                      item.notification_type ===
                                      "SUBSCRIPTION-REQ"
                                        ? navigate(urls.SubscriptionTabs)
                                        : navigate("/notifications");
                                    }}
                                  >
                                    <HStack w="100%">
                                      {" "}
                                      <Avatar
                                        bg={"brand.900"}
                                        w="5"
                                        h="5"
                                        name={item.name}
                                      />
                                      <Text w="100%" fontSize={"12px"}>
                                        {item.client_name} has requested for
                                        approval
                                      </Text>
                                    </HStack>
                                  </Card>
                                </GridItem>
                              );
                            })
                          ) : (
                            <GridItem>
                              <Card
                                textAlign="center"
                                bg="gray.100"
                                boxShadow="rgba(0, 0, 0, 0.24) 0px 2px 8px"
                                h="70px"
                                display={"flex"}
                                p="5"
                              >
                                No Data Available
                              </Card>
                            </GridItem>
                          )}
                        </Grid>
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </span>
            </Tooltip>

            <Tooltip
              label="Kill Switch"
              hasArrow
              placement="bottom"
              bg=" brand.900"
              color="brand.400"
            >
              <Popover
                isOpen={showPopover}
                // onClose={() => setShowPopover(false)}
                placement="bottom-start"
              >
                <PopoverTrigger>
                  <span
                    onClick={() => {
                      // handleKillSwitch();
                      setShowPopover(true);
                      // navigate("/dashboard");
                    }}
                  >
                    <KillSwitchIcon height={"32px"} width="32px" />
                  </span>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent minW="350px" minH="150px">
                    <PopoverArrow />
                    <PopoverHeader>Confirmation</PopoverHeader>
                    <PopoverBody>
                      <Text my={3}>
                        Do you want to activate the kill switch?
                      </Text>
                      <Flex
                        width={"65%"}
                        float="right"
                        justifyContent={"space-between"}
                      >
                        <Button
                          color="brand.700"
                          bg="brand.900"
                          onClick={() => {
                            Toast({
                              title: "Kill Switch Active successfully",
                              status: "success",
                              position: "top-right",
                              isClosable: true,
                            });
                            setShowPopover(false);
                            handleKillSwitch();
                            // handleRefreshPage();
                            navigate("/dashboard");
                          }}
                        >
                          Confirm
                        </Button>
                        <Button
                          bg="brand.900"
                          color="brand.700"
                          onClick={() => setShowPopover(false)}
                        >
                          Cancel
                        </Button>
                      </Flex>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </Tooltip>

            <Tooltip
              label="Logout"
              hasArrow
              placement="bottom"
              bg=" brand.900"
              color="brand.400"
            >
              <span>
                <span style={{ cursor: "pointer" }} onClick={logout}>
                  <LogoutIcon height={"32px"} width="32px" />
                </span>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Des;
