import { Box, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { detailsCard, get_Error_Rejected_count, openPositionCount } from "apis/dashboard";

import { useEffect, useState, lazy, Suspense } from "react";
import { AiOutlineStop } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import urls from "utils/routes";
import theme from "./utils/theme"
import { MdCheckCircle, MdCancel, MdOutlineError, MdPending } from "react-icons/md";
const ActiveUserIcon = lazy(() => import("assets/icons/ActiveUserIcon"));
const DeletedUserIcon = lazy(() => import("assets/icons/DeletedUserIcon"));
const PLIcon = lazy(() => import("assets/icons/PLIcon"));
const PausedUserIcon = lazy(() => import("assets/icons/PausedUserIcon"));
const TradeHistoryIcon = lazy(() => import("assets/icons/TradeHistoryIcon"));
const TradegraphIcon = lazy(() => import("assets/icons/TradegraphIcon"));

const Cardsdashboad = () => {
  const brand=theme.colors.brand[400]
  const location = useLocation();
  const navigate = useNavigate();
  const [activeuser,setAtive]=useState(0)
  const [pauseuser,setPause]=useState(0)
  const [Discarduser,setDiscard]=useState(0)
  const [pending,setPending]=useState(0)
const [positions,setPositions]=useState(0)
const [error,setError]=useState(0)
  
  // if (location.pathname == "/") {
  //   return null;
  // }
  const pathname = window.location.pathname;
  useEffect(()=>{
   
      dashboarddata()
   
  },[location])

  const dashboarddata = async () => {
    try {
      const [detailsCardResponse, ErrorRejResponse, openPositionCountResponse] = await Promise.all([
        detailsCard(),get_Error_Rejected_count(), openPositionCount()
       
        
        // clientData(),
      ]);
      
     
      const {total_pendings,active_client,pause_client,discard_client,...rest}=detailsCardResponse.data[0];
      setAtive(active_client)
      setPause(pause_client)
      setDiscard(discard_client)
      setPending(total_pendings)
      setPositions(openPositionCountResponse?.data[0]?.total_open_position)
      setError(ErrorRejResponse?.data[0]?.total_error_position)
  
     
    } catch (error) {
      console.error(error);
    }
  };
  
 
 
  return (

    
    <Box ml="20px" mt="2" w="98%" zIndex={"18"} >
      <Grid templateColumns="repeat(3, 1fr)" gap="4%" w="100%">
        <GridItem h="100%">
          <Box
            w="100%"
            h="100%"
            display="flex"
            alignItems={"center"}
            position="relative"
            flexDirection={"column"}
           
            ml="0"
          >
            <Box
             
              width={{ base: '80%', md: '50%', lg: '33%' }}
              height="23px"
              flexShrink="0"
              borderRadius="0.3125rem"
              background="brand.900"  
              position="relative"
              display="flex"
              alignItems="center"
           zIndex={"10"}
              justifyContent={"center"}
            >
              
              <Text
                color="brand.500"
                display="flex"
              alignItems="center"
              gap="1"
              justifyContent={"center"}
                fontSize="0.75rem"
                borderColor="transparent"
                      fontWeight="700"
                lineHeight="normal"
                textAlign={"center"}
                w="100%"
              ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
            >
              <path
                d="M12.5 1.83203H2.5L3.72016 6.83203H11.1472L12.5 1.83203Z"
                fill={brand}
              />
              <path d="M0 1H2.35323L4.70647 9H11" stroke={brand} />
              <circle cx="5.83333" cy="11.1654" r="0.833333" fill={brand} />
              <circle cx="9.16658" cy="11.1654" r="0.833333" fill={brand} />
            </svg>
                Order Status
              </Text>
            </Box>
            <Box
              w="65%"
              h="58px"
              flexShrink="0"
              borderRadius="0.625rem 0.625rem 0rem 0.625rem"
             bg="brand.200"
              position="absolute"
              zIndex={4}
              top="3"
            ></Box>
            <Box
              w="100%"
              h="60%"
              position="absolute"
              top="10"
              display="flex"
             
              justifyContent={"space-between"}
            
             
            >
              <Box
              // width={{ base: '60px', md: '80px', lg: '100px' }}
              w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={()=> navigate(urls.openRequest)}
              >
                <Box
                  marginTop={"2"}
                  display="flex"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <TradegraphIcon width="30px" height="30px" />
                </Box>
                <Box w="50%" m="auto">
                  <Text
                    mt="1"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"  
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                   
                    fontSize="1.0125rem"
                    borderColor="transparent"
                    fontWeight="700"
                    lineHeight="normal"
                 
                    marginBottom={"1"}
                  >
                    {positions}
                  </Text>
                 
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                  fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                 
                >
                  Open{" "}
                </Text>
              </Box>  
              <Box
              // width={{ base: '60px', md: '80px', lg: '100px' }}
              w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={()=> navigate("/Pendingorders")}
              >
                <Box
                  marginTop={"2"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                  
                  // mt="6"
                 
                >
                 <Icon color="brand.600" f w="25px"
                          h="25px"
                          as={ MdPending }/>
                </Box>
                
                <Box w="50%" m="auto">
                  <Text
                   mt="2"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"  
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                    borderColor="transparent"
                      fontWeight="700"
                    fontSize="1.0125rem"
                    fontStyle="normal"
                   
                    lineHeight="normal"
                    // fontFamily: DM Sans;
                    marginBottom={"1"}
                  >
                   {pending}
                  </Text>
                  
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                      fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                  
                >
                  Pending{" "}
                </Text>
              </Box>
              <Box
              // width={{ base: '60px', md: '80px', lg: '100px' }}
              w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={()=> navigate("/Errororders")}
              >
                <Box
                  marginTop={"2"}
                  display="flex"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                
                  <Icon color="brand.600" f w="25px"
                          h="25px"
                          as={ MdCancel }/>
                </Box>
                
                <Box w="50%" m="auto">
                  <Text
                    mt="3"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"  
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                    borderColor="transparent"
                      fontWeight="700"
                    fontSize="1.0125rem"
                    marginBottom={"1"}
                    // fontFamily: DM Sans;
                  >
                   {error}
                  </Text>
                 
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                      fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                 
                >
                  Error{" "}
                </Text>
              </Box>
            </Box>
          </Box>
        </GridItem>
        <GridItem h="100%">
          <Box
            w="100%"
            h="150px"
            display="flex"
            alignItems={"center"}
            position="relative"
            flexDirection={"column"}
          >
            <Box
             width={{ base: '80%', md: '50%', lg: '33%' }}
              height="23px"
              flexShrink="0"
              borderRadius="0.3125rem"
              background="brand.900"  
              position="relative"
              display="flex"
              alignItems="center"
              zIndex={"10"}
              justifyContent={"center"}
              
            >
           
              <Text
                color="brand.500"
                zIndex={"10"}
                fontSize="0.75rem"
                fontStyle="normal"
                borderColor="transparent"
                      fontWeight="700"
                lineHeight="normal"
                display="flex"
              alignItems="center"
              gap="1"
              justifyContent={"center"}
              >
                   <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
              >
                <rect
                  x="1"
                  y="6.63672"
                  width="7"
                  height="5.21214"
                  rx="2"
                  fill="#A3AED0"
                  stroke="#A3AED0"
                  stroke-width="2"
                />
                <path
                  d="M6.60762 3.09123C6.60762 4.20232 5.6495 5.18247 4.36729 5.18247C3.08507 5.18247 2.12695 4.20232 2.12695 3.09123C2.12695 1.98015 3.08507 1 4.36729 1C5.6495 1 6.60762 1.98015 6.60762 3.09123Z"
                  fill="#A3AED0"
                  stroke="#A3AED0"
                  stroke-width="2"
                />
                <rect
                  x="1"
                  y="6.63672"
                  width="7"
                  height="5.21214"
                  rx="1"
                  fill={brand}
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M6.60762 3.09123C6.60762 4.20232 5.6495 5.18247 4.36729 5.18247C3.08507 5.18247 2.12695 4.20232 2.12695 3.09123C2.12695 1.98015 3.08507 1 4.36729 1C5.6495 1 6.60762 1.98015 6.60762 3.09123Z"
                  fill={brand}
                  stroke={brand}
                  stroke-width="2"
                />
                <rect y="9.82031" width="9" height="3.27273" fill={brand} />
                <path
                  d="M0.818115 7.54687C0.818115 6.99459 1.26583 6.54688 1.81812 6.54688H7.18175C7.73404 6.54688 8.18175 6.99459 8.18175 7.54688V10.6378H0.818115V7.54687Z"
                  fill={brand}
                />
                <path
                  d="M13.5 2.09375L14.2858 4.51219H16.8287L14.7714 6.00687L15.5572 8.42531L13.5 6.93063L11.4428 8.42531L12.2286 6.00687L10.1713 4.51219H12.7142L13.5 2.09375Z"
                  fill={brand}
                />
              </svg>
                User Status
              </Text>
            </Box>
            <Box
              w="65%"
              h="58px"
              flexShrink="0"
              borderRadius="0.625rem 0.625rem 0rem 0.625rem"
             bg="brand.200"
              position="absolute"
              zIndex={4}
              top="3"
            ></Box>
            <Box
              w="100%"
              h="60%"
              position="absolute"
              top="10"
              display="flex"
              // justifyContent="space-evenly"
              justifyContent={"space-between"}
            >
              <Box
              // width={{ base: '60px', md: '80px', lg: '100px' }}
              w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={() => {
                  //   handleactive()
                  navigate("/clients", { state: { api_status: "active" } });
                }}
              >
                <Box
                  marginTop={"2"}
                  display="flex"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <ActiveUserIcon width="30px" height="30px" />
                </Box>
                <Box w="50%" m="auto">
                  <Text
                    mt="1"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"  
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                    borderColor="transparent"
                    fontWeight="700"
                    fontSize="1.0125rem"
                    fontStyle="normal"
                    marginBottom={"1"}
                    lineHeight="normal"
                    // fontFamily: DM Sans;
                  >
                   {activeuser}
                  </Text>
                  {/* <Text
                    fontSize={"13px"}
                    position="absolute"
                    bottom={0}
                    right="3"
                    padding={0}
                    fontFamily="Darker Grotesque"
                    fontStyle="normal"
                    color="#4D4DFF"
                    textAlign="center"
                    fontWeight={400}
                  >
                    Open{" "}
                  </Text> */}
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                      fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                 
                >
                  Active{" "}
                </Text>
              </Box>
              <Box
              // width={{ base: '60px', md: '80px', lg: '100px' }}
              w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={() => {
                  navigate("/clients", { state: { api_status: "pause" } });
                }}
              >
                <Box
                  marginTop={"2"}
                  display="flex"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <PausedUserIcon width="30px" height="30px" />
                </Box>
                <Box w="50%" m="auto">
                  <Text
                    mt="1"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"  
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                    borderColor="transparent"
                    fontWeight="700"
                    fontSize="1.0125rem"
                    marginBottom={"1"}
                   
                    // fontFamily: DM Sans;
                  >
                    {pauseuser}
                  </Text>
                  {/* <Text
                    fontSize={"13px"}
                    position="absolute"
                    bottom={0}
                    right="3"
                    padding={0}
                    fontFamily="Darker Grotesque"
                    fontStyle="normal"
                    color="#4D4DFF"
                    textAlign="center"
                    fontWeight={400}
                  >
                    Open{" "}
                  </Text> */}
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                  fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
               
                >
                  Paused{" "}
                </Text>
              </Box>
              <Box
              // width={{ base: '60px', md: '80px', lg: '100px' }}
              w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                // border="1px solid gray"
                onClick={() => {
                  navigate("/clients", { state: { api_status: "discard" } });
                }}
              >
                <Box
                  marginTop={"2"}
                  display="flex"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <DeletedUserIcon width="30px" height="30px" />
                </Box>
                <Box w="50%" m="auto">
                  <Text
                    mt="1"
                    borderRadius={4}
                    // width="0.8125rem"
                    height="1.4rem"
                    flex-shrink="0"
                    bordeRadius="0.0625rem"
                    background="brand.600"  
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="brand.700"
                    borderColor="transparent"
                      fontWeight="700"
                    fontSize="1.0125rem"
                    fontStyle="normal"
                    marginBottom={"1"}
                    lineHeight="normal"
                    // fontFamily: DM Sans;
                  >
                   {Discarduser}
                  </Text>
                
                </Box>
                <Text
                  textAlign="center"
                  color="brand.600"
                  borderColor="transparent"
                  fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                  
                >
                  Discard{" "}
                </Text>
              </Box>
            </Box>
          </Box>
        </GridItem>
        <GridItem h="100%">
          <Box
            w="100%"
            h="150px"
            display="flex"
            alignItems={"center"}
            position="relative"
            flexDirection={"column"}
          >
            <Box
             width={{ base: '80%', md: '50%', lg: '33%' }}
              height="23px"
              flexShrink="0"
              borderRadius="0.3125rem"
              background="brand.900"  
              position="relative"
              display="flex"
              alignItems="center"
            
              justifyContent={"center"}
              zIndex={"10"}
            >
            
              <Text
                color="brand.500"
                borderColor="transparent"
                fontWeight="700"
                fontSize="0.75rem"
                fontStyle="normal"
                display="flex"
                alignItems="center"
                gap="1"
                justifyContent={"center"}
            
                lineHeight="normal"
              >
                  <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
              >
                <rect
                  y="1.57031"
                  width="11"
                  height="7.85714"
                  rx="2"
                  fill={brand}
                />
                <path
                  d="M0 1C0 0.447715 0.447715 0 1 0H2.42466C4.08151 0 5.42466 1.34314 5.42466 3V3.61644H0V1Z"
                  fill={brand}
                />
              </svg>
                Broker Records
              </Text>
            </Box>
            <Box
              w="65%"
              h="58px"
              flexShrink="0"
              borderRadius="0.625rem 0.625rem 0rem 0.625rem"
             bg="brand.200"
              position="absolute"
              zIndex={4}
              top="3"
            ></Box>
            <Box
              w="100%"
              h="60%"
              position="absolute"
              top="10"
              display="flex"
              // justifyContent="space-evenly"
              justifyContent={"space-between"}
            >
              <Box
              // width={{ base: '60px', md: '80px', lg: '100px' }}
              w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                onClick={() => {
                  //   handleactive()
                  navigate("/dashboard");
                }}
              >
                <Box display={"flex"} mt="1" justifyContent={"center"}>
                  <TradeHistoryIcon width="42px" height="42px" />
                </Box>
                {/* <Box  m="auto" w="50%" > */}

                <Text
                  m="auto"
                  width={{ base: '100%', md: '80%', lg: '50%' }}
                  color="brand.600"
                  textAlign="center"
                  borderColor="transparent"
                      fontWeight="700"
                  fontSize="11px"
                  fontStyle="normal"
                
                >
                  Trade History
                </Text>
                {/* </Box> */}
              </Box>
              <Box
              // width={{ base: '60px', md: '80px', lg: '100px' }}
              w="27%"
             
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                onClick={() => {
                  //   handleactive()
                  navigate("/P&L");
                }}
              >
                <Box display={"flex"} mt="1" justifyContent={"center"}>
                  <PLIcon width="42px" height="42px" />
                </Box>
                {/* <Box  m="auto" w="50%" > */}

                <Text
                  m="auto"
                  width={{ base: '100%', md: '80%', lg: '60%' }}
                  color="brand.600"
                  textAlign="center"
                
                  fontSize="11px"
                  fontStyle="normal"
                 
                  borderColor="transparent"
                  fontWeight="700"
                >
                  P&L Statement
                </Text>
                {/* </Box> */}
              </Box>
              <Box
              // width={{ base: '60px', md: '80px', lg: '100px' }}
              w="27%"
                h="100%"
                flexShrink={0}
                borderRadius="0.625rem"
                bg="brand.700"
                cursor={"pointer"}
                zIndex="5"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                onClick={() => {
                  //   handleactive()
                  navigate("/broker");
                }}
              >
                <Box display={"flex"} mt="1" justifyContent={"center"}>
                  <PLIcon width="42px" height="42px" />
                </Box>
                {/* <Box  m="auto" w="50%" > */}

                <Text
                  m="auto"
                 
                  width={{ base: '100%', md: '80%', lg: '60%' }}
                  color="brand.600"
                  textAlign="center"
                 
                  fontSize="11px"
                  borderColor="transparent"
                  fontWeight="700"
                >
                  Broker Mapping
                </Text>
                {/* </Box> */}
              </Box>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Cardsdashboad;
