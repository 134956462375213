import {
  Grid,
  GridItem,

  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,

  useToast,
} from "@chakra-ui/react";
import { createClient } from "apis/client";
import CloseIcon from "assets/icons/close";
import FormButton from "components/controls/Button";
import FormInput from "components/controls/FormInput";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import urls from "utils/routes";
import CustomForm from "utils/useForm";

const AddClient = (props) => {
  const { isOpen, onClose, isEdit, client, refreshData } = props;
  const navigate = useNavigate();
  const methods = useForm();
  const toast = useToast();
  const [isExits, setIsExits] = useState("");
  let primary_color=localStorage.getItem("primary_color") || process.env.REACT_APP_PRIMARY_COLOR;
  let secondary_color=localStorage.getItem("secondary_color") || process.env.REACT_APP_SECONDARY_COLOR;
  useEffect(() => {
    if (isEdit) {
      methods.reset(client);
    }
  
  }, [isEdit]);
  const onAddClient = (data) => {
    try {
     
      createClient(data, isEdit ? "put" : "post")
        .then((res) => {

          toast({
            title: isEdit
              ? "User Updated Successfully!"
              : "User Added Successfully.",
            status: "success",
            position: "top-right",
            isClosable: true,
          });

          onClose();
          navigate(urls.dashboard);
          
        })
        .catch((error) => {
          console.log("Error", error);
          toast({
            title: "User already exists",
            status: "error",
            position: "top-right",
            isClosable: true,
          });
          if (error.response.data.status == "Internal Server Error") {
            isOpen();
          } else {
            onClose();
          }
        });
      
    } catch (error) {
      console.log(error);
      toast({
        title: "Something went wrong.",
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    } finally {
      refreshData();
    }
  };
  const company_name=localStorage.getItem("company_name")  || process.env.REACT_APP_NEWCLIENT_TEXT;
  
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"2xl"}>
      <ModalOverlay />
      <CustomForm
        methods={methods}
        onSubmit={methods.handleSubmit(onAddClient)}
      >
        <ModalContent overflow={"hidden"} borderTopRightRadius={"18"}
          borderTopLeftRadius={"18"}  borderBottomLeftRadius={"18"}
          borderBottomRightRadius={"18"}   >
          <ModalHeader
            fontSize={22}
            fontWeight="700"
         
            bg={"brand.900"}
            color={"brand.500"}
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
           
            borderTopRightRadius={"18"}
          borderTopLeftRadius={"18"}
          >
            Add User
            <ModalCloseButton position={"relative"} top={0} right={0}>
              <CloseIcon />
            </ModalCloseButton>
          </ModalHeader>
          <ModalBody p={4}>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem w="100%">
                <FormInput
                  name="name"
                  label="Name"
                  control={methods.control}
                  error={methods.formState.errors}
                  rules={{
                    required: "This field is required.",
                  }}
                
                 
                  isRequired
                />
              </GridItem>
              <GridItem w="100%">
                <FormInput
                  name="email"
                  label="Email"
                  control={methods.control}
                  error={methods.formState.errors}
                  rules={{
                    required: "This field is required.",
                  }}
                  //   size="lg"
                  type="email"
                  isRequired
                />
              </GridItem>
              <GridItem w="100%">
                <FormInput
                  name="keev_email_id"
                 
                  label={company_name+ " Email ID"}
                  control={methods.control}
                  error={methods.formState.errors}
                  rules={{
                    required: "This field is required.",
                  }}
                  //   size="lg"
                  isRequired
                  type="email"
                  isDisabled={isEdit ? true : false}
                />
              </GridItem>
              <GridItem w="100%">
                <FormInput
                  name="keev_secret_id"
                  label={company_name + " Secret Key"}
                  control={methods.control}
                  error={methods.formState.errors}
                  rules={{
                    required: "This field is required.",
                  }}
                  //   size="lg"
                  isRequired
                />
              </GridItem>
              {/* <GridItem w="100%">
                <FormInput
                  name="telegram_bot_id"
                  label="Telegram Bot ID"
                  control={methods.control}
                  error={methods.formState.errors}
                  //   size="lg"
                />
              </GridItem> */}
              {/* <GridItem w="100%">
                <FormInput
                  name="telegram_bot_number"
                  label="Telegram Bot Number"
                  control={methods.control}
                  error={methods.formState.errors}
                  //   size="lg"
                />
              </GridItem> */}
              {/* <GridItem w="100%">
                <FormInput
                  name="started_fund"
                  label="Started Fund"
                  control={methods.control}
                  error={methods.formState.errors}            
                  //   size="lg"
                />
              </GridItem> */}

              <GridItem w="100%">
                {/* <HStack alignItems={'flex-end'}>
                        <FormNumberInput
                          name="started_fund"
                          label="Started Fund"
                          control={methods.control}
                          error={methods.formState.errors} 
                          rules={{
                            required: 'This field is required.',
                          }}
                          // isRequired
                          // defaultValue={item?.scrip_ratio || ''}
                        />
                      </HStack> */}
              </GridItem>
            </Grid>
          </ModalBody>
          {/* <ModalFooter background={'#DBF5F0'} gap={4} p={4}> */}
          <ModalFooter
            
            gap={4}
            p={4}
          >
            <FormButton
              onClick={onClose}
              text="Close"
              // variant="link"
              // color="#141414"
            />
            <FormButton
              type="submit"
              text="Save User"
              // onClick={() => (isExits ? null : navigate("/clients"))}
            />
          </ModalFooter>
        </ModalContent>
      </CustomForm>
    </Modal>
  );
};

export default AddClient;
